/* eslint-disable */
import HttpService from '../HttpService'

export default class SubscriptionService {
  constructor() {
    this._httpService = new HttpService('odoo');
  }

  async CheckPaymentStatus(id) {
    return await this._httpService.get(`transaction-history/last/${id}`);
  }

  async getSubscriptionById(id){
    return await this._httpService.get(`subscription/${id}/next-billing-date`)
  }

  async getTransactionList(params){
    return await this._httpService.get('transaction/', params);
  }

  async getTransactionById(id){
    return await this._httpService.get(`transaction/${id}`);
  }

}
