<template>
  <v-container class="bg-glass-effect px-3 pt-10" fluid>
     <BackArrow style="position: initial" :action="() => onClickBackArrow()"/>
    <v-row  class="px-3">
      <v-col class="pt-7 pb-0" cols="12">
        <h1 class="generic-title  mb-5">Meus pagamentos</h1>
      </v-col>
      <v-col v-if="subscriptionId" cols="12">
        <h3 class="mt-5 mb-5 font-weight-black">Telemedicina</h3>
        <v-row class="align-center " >
          <div class="ml-2 mr-2">
            <v-img width="3vw" v-if="this.creditCardBrand" :src="this.creditCardBrand"></v-img>
          </div>
          <div class="">&#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022;
            <span class="font-weight-bold"> {{subscription.cardNumber}}</span>
          </div>
        </v-row>
        <h4 class=" mt-5 mb-5 ">Sua próxima data de cobrança é: <span class="red--text font-weight-bold"> {{subscription.nextBillingDate}} </span></h4>
      </v-col>
      <v-col v-if="subscriptionId" class="mt-4">
        <v-row class="ml-1 align-center ">
          <div class="mt-3 text-decoration-underline font-weight-black" style="cursor: pointer" @click="redirectToPaymentHistory()" >Histórico de pagamento </div>
        </v-row>
        <v-row class="ml-1 align-center ">
          <div class="mb-5 text-decoration-underline font-weight-black" style="cursor: pointer" @click="redirectToChangeCreditCard()" >Alterar os dados do meu cartão de crédito </div>
        </v-row>
      </v-col>
      <v-col v-if="!subscriptionId" cols="12">
        <h3 class="mt-5 mb-5 font-weight-black">Você ainda não possui assinatura ou pagamentos para consulta.</h3>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import BackArrow from "@/components/covid/back-arrow";
import SubscriptionService from "@/services/odoo/SubscriptionService";
//import GenericBtn from "@/components/generic-btn/index.vue";
//import HealthChallengeService from "@/services/healthcare/HealthChallengeService";
//import UserHealthChallengeService from "@/services/healthcare/UserHealthChallengeService";
//import { updateUser } from "../../common/securityserver";
//import $util from "../../common/util";
//import DatePicker from '../../components/date-picker/index.vue';


export default {
  data: () => ({
    subscriptionId: null,
    creditCardBrand: null,
    subscription: {
      cardBrand: null,
      cardNumber: null,
      nextBillingDate: null
    }
  }),
 components: {
    BackArrow,
    //GenericBtn,
    //DatePicker
  },
  mounted() {
    this.subscriptionService = new SubscriptionService();
    this.subscriptionId = this.$auth.user().beneficiaries.find(beneficiary => beneficiary.subscriptionId != null).subscriptionId
    this.getSubscriptionById(this.subscriptionId);
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "updateMenuState", "toogleMainDialog"]),

    async getSubscriptionById(id){
      if(id){
        this.loading(true)
        await this.subscriptionService.getSubscriptionById(id).then((response) => {
          this.subscription = response.data;
          switch (this.subscription.cardBrand.toLowerCase()){
            case 'visa':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_visa.png'
              break;
            case 'amex':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_american_express.png'
              break;
            case 'hipercard':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_hiper_card.png'
              break;
            case 'mastercard':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_master_card.png'
              break;
            case 'elo':
              this.creditCardBrand = '/img/icons/credit-card/credit_card_img_elo.png'
              break;
          }
        }).catch((error) => {
          if (error && error.response && error.response.data && error.response.status === 404) {
            this.showmsg({text: "Assinatura não encontrada", type: "error"});
          } else {
            this.showmsg({text: "Ocorreu um erro ao tentar carregar a assinatura.", type: "error"});
          }
        }).finally(() => {
          this.loading(false)
        });
      }
    },
    redirectToPaymentHistory(){
      this.$router.push({
        name: "payment-history", query: { subscriptionId: this.subscriptionId }
      });
    },
    redirectToChangeCreditCard(){
      this.$router.push({ name: 'telemedicinePayment', params: { changeCreditCard: true, subscriptionId: this.subscriptionId }})
    },
    onClickBackArrow(){
      this.$router.push({name: "home"});
    }
  },
  created() {
  },
  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function () {
        return this.menu.rigth;
      },
      set: function (value) {
        this.updateMenuState({ rigth: value });
      },
    },
  },

};
</script>

<style scoped>
.card-text{
  font-size: 28px !important;
  color: #532E88 !important;
  font-weight: bold !important;
  font-family: 'Open Sans' !important;
}
.text-alert{
  color: #532E88 !important;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Open Sans' !important;
}

</style>
